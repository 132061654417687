app.controller("EntityLanding", ['$scope', '$element', '$sce', '$timeout', 'searchService', 'filterService', 'queryStringService', 'helperService', 'landingHelperService', function ($scope, $element, $sce, $timeout, searchService, filterService, queryStringService, helperService, landingHelperService) {
    $scope.isLoading = false;
    $scope.filters = [];
    $scope.flatFilters = [];
    $scope.list = [];
    $scope.searchBys = [];
    $scope.selectedSearchBy = null;
    $scope.currentResultCount = 0;
    $scope.totalResultCount = 0;
    $scope.hasSearchRun = false;
    $scope.hasSearchStarted = false;
    $scope.searchText = '';
    $scope.searchBy = 'keyword';
    $scope.anyFilterTrayOpen = false;
    $scope.resultsLabel = '';
    $scope.enableFeatured = false;
    $scope.enableInfiniteScroll = false;
    var viewMoreClicked = false;

    var sanitizedFields = [];
    var parameterNames = {
        SearchType: 'searchType',
        PageSize: 'pageSize',
        PageNum: 'pageNum',
        SortBy: 'sortBy',
        SortOrder: 'sortOrder',
        ScrollPosition: 'scroll',
        Capability: 'services',
        Location: 'offices',
        Education: 'schools',
        Admission: 'courts',
        SpokenLanguage: 'languages',
        Dates: 'dates',
        Types: 'types',
        Topics: 'topics',
        Professionals: 'professionals',
        Sections: 'sections',
        Language: 'language',
        Name: 'name',
        Keyword: 'keyword',
        Letter: 'letter',
        NoSkip: 'noSkip',
        IsFeatured: 'isFeatured',
        DiversityGroups: 'diversitygroups',
        Diversity: 'diversity'
    };
    var qsSeparator = '|';
    var firstSearch = true;
    var searchType = 'All';
    var pageSize = 20;
    var pageNum = 0;
    var sortBy = 0;
    var sortOrder = 0;
    var language = 'en';
    var scrollPosition = 0;
    var isFeatured = false;
    var featuredPageSize = 5;
    var preventAutoScroll = false;
    var defaultSortBy = 0;

    var init = function () {
        //get any variables from html
        if ($element.attr('data-page-size')) {
            pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize);
        }
        if ($element.attr('data-sanitized-fields')) {
            sanitizedFields = $element.attr('data-sanitized-fields').split(',');
        }
        if ($element.attr('data-language')) {
            language = $element.attr('data-language');
        }
        if ($element.attr('data-searchType')) {
            searchType = $element.attr('data-searchType');
        }
        if ($element.attr('data-enable-featured')) {
            $scope.enableFeatured = $element.attr('data-enable-featured') === '1'
        }
        if ($element.attr('data-featured-page-size')) {
            featuredPageSize = helperService.tryParseInt($element.attr('data-featured-page-size'), featuredPageSize);
        }
        if (document.getElementById('json-filters') != null) {
            $scope.filters = JSON.parse(document.getElementById('json-filters').text);
        }
        if (document.getElementById('json-searchbys') != null) {
            $scope.searchBys = JSON.parse(document.getElementById('json-searchbys').text);
            $scope.selectedSearchBy = ($scope.searchBys && $scope.searchBys.length > 0 ? $scope.searchBys[0] : null);
        }
        if (document.getElementById('json-sortbys') != null) {
            $scope.sortBys = JSON.parse(document.getElementById('json-sortbys').text);
            for (var i = 0; i < $scope.sortBys.length; i++) {
                if ($scope.sortBys[i].IsSelected === true) {
                    defaultSortBy = $scope.sortBys[i].Value;
                    sortBy = $scope.sortBys[i].Value;
                    sortOrder = $scope.sortBys[i].DefaultSortOrder;
                    break;
                }
            }
        }

        if (queryStringService.hasQueryString()) {
            //if there are any query string parameters set filters, run search
            setFilters();
        }
        search();
    };
    
    var setFilters = function () {
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.PageNum))) {
            pageNum = parseInt(queryStringService.getParameterByName(parameterNames.PageNum));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.SortBy))) {
            sortBy = parseInt(queryStringService.getParameterByName(parameterNames.SortBy));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.SortOrder))) {
            sortOrder = parseInt(queryStringService.getParameterByName(parameterNames.SortOrder));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.ScrollPosition))) {
            scrollPosition = parseInt(queryStringService.getParameterByName(parameterNames.ScrollPosition));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Capability))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Capability, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Location))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Location, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Education))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Education, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Admission))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Admission, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.SpokenLanguage))) {
            landingHelperService.checkFiltersByParameter(parameterNames.SpokenLanguage, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Dates))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Dates, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Types))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Types, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Sections))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Sections, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Topics))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Topics, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Professionals))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Professionals, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.DiversityGroups))) {
            landingHelperService.checkFiltersByParameter(parameterNames.DiversityGroups, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Diversity))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Diversity, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Name))) {
            var name = queryStringService.getParameterByName(parameterNames.Name);
            if (name) {
                landingHelperService.addFilter(parameterNames.Name, name, name);
            }
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Keyword))) {
            var keyword = queryStringService.getParameterByName(parameterNames.Keyword);
            if (keyword) {
                landingHelperService.addFilter(parameterNames.Keyword, keyword, keyword);
            }
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Letter))) {
            var letter = queryStringService.getParameterByName(parameterNames.Letter);
            if (letter) {
                landingHelperService.addFilter(parameterNames.Letter, letter, letter);
                setActiveSortBy(1); //1 for alpha
            }
        }

        $scope.flatFilters = landingHelperService.getActiveFilters();
    };

    var search = function () {
        isFeatured = $scope.enableFeatured && $scope.flatFilters.length === 0;

        if ($scope.flatFilters.length === 0 && !$scope.enableFeatured) {
            $scope.hasSearchStarted = false;
            $scope.hasSearchRun = false;
            setActiveSortBy(defaultSortBy);
            buildQueryString();
            return;
        }

        $scope.hasSearchStarted = true;
        $scope.isLoading = true;

        searchService.search(buildQueryString(), searchType, handleSearchSuccess, handleSearchError);
    };

    var handleSearchSuccess = function (response) {
        angular.forEach(response.data.list, function (item) {
            angular.forEach(sanitizedFields, function (name) {
                item[name] = $sce.trustAsHtml(item[name]);
            });
        });

        if (pageNum > 0 && !firstSearch) {
            $scope.list = $scope.list.concat(response.data.list);
        }
        else {
            $scope.list = response.data.list;
        }
        $scope.resultsLabel = response.data.resultsLabel;

        if (scrollPosition > 0) {
            $timeout(function () {
                window.scroll(0, scrollPosition);
            });
        }
        else if (!(firstSearch && $scope.enableFeatured) && !preventAutoScroll) {
            $timeout(function () {
                var nav = document.getElementById('site-nav--js')
                if (!nav.classList.contains('site-nav--hidden')) {
                    nav.classList.add('site-nav--hidden');
                }
                document.getElementById('search-filters').scrollIntoView({ behavior: 'smooth' });
            });
        }

        $scope.currentResultCount = $scope.list.length;
        $scope.totalResultCount = response.data.count;
        $scope.hasSearchRun = true;
        $scope.isLoading = false;
        $scope.enableInfiniteScroll = !($scope.currentResultCount == $scope.totalResultCount) && viewMoreClicked;
        firstSearch = false;
        preventAutoScroll = false;
    };

    var handleSearchError = function (data, status, headers, config) {
        $scope.isLoading = false;
    };

    var buildQueryString = function (scroll) {
        var qs = '';
        qs = queryStringService.addQueryString(parameterNames.SearchType, searchType, qs);
        if (isFeatured) {
            qs = queryStringService.addQueryString(parameterNames.PageSize, featuredPageSize, qs);
        }
        else {
            qs = queryStringService.addQueryString(parameterNames.PageSize, pageSize, qs);
        }
        qs = queryStringService.addQueryString(parameterNames.PageNum, pageNum, qs);
        qs = queryStringService.addQueryString(parameterNames.SortBy, sortBy, qs);
        qs = queryStringService.addQueryString(parameterNames.SortOrder, sortOrder, qs);
        qs = queryStringService.addQueryString(parameterNames.Language, language, qs);
        qs = queryStringService.addQueryString(parameterNames.NoSkip, firstSearch, qs);
        qs = queryStringService.addQueryString(parameterNames.IsFeatured, isFeatured, qs);

        if (scroll && scroll > 0) {
            qs = queryStringService.addQueryString(parameterNames.ScrollPosition, scroll, qs);
        }

        var nameString = filterService.getQueryStringBySection(parameterNames.Name, ' ');
        if (!helperService.isNullOrEmpty(nameString)) {
            qs = queryStringService.addQueryString(parameterNames.Name, nameString, qs);
        }
        var keywordString = filterService.getQueryStringBySection(parameterNames.Keyword, ' ');
        if (!helperService.isNullOrEmpty(keywordString)) {
            qs = queryStringService.addQueryString(parameterNames.Keyword, keywordString, qs);
        }
        var letterString = filterService.getQueryStringBySection(parameterNames.Letter, qsSeparator);
        if (!helperService.isNullOrEmpty(letterString)) {
            qs = queryStringService.addQueryString(parameterNames.Letter, letterString, qs);
        }
        var capabilityString = filterService.getQueryStringBySection(parameterNames.Capability, qsSeparator);
        if (!helperService.isNullOrEmpty(capabilityString)) {
            qs = queryStringService.addQueryString(parameterNames.Capability, capabilityString, qs);
        }
        var locationString = filterService.getQueryStringBySection(parameterNames.Location, qsSeparator);
        if (!helperService.isNullOrEmpty(locationString)) {
            qs = queryStringService.addQueryString(parameterNames.Location, locationString, qs);
        }
        var educationString = filterService.getQueryStringBySection(parameterNames.Education, qsSeparator);
        if (!helperService.isNullOrEmpty(educationString)) {
            qs = queryStringService.addQueryString(parameterNames.Education, educationString, qs);
        }
        var admissionString = filterService.getQueryStringBySection(parameterNames.Admission, qsSeparator);
        if (!helperService.isNullOrEmpty(admissionString)) {
            qs = queryStringService.addQueryString(parameterNames.Admission, admissionString, qs);
        }
        var languageString = filterService.getQueryStringBySection(parameterNames.SpokenLanguage, qsSeparator);
        if (!helperService.isNullOrEmpty(languageString)) {
            qs = queryStringService.addQueryString(parameterNames.SpokenLanguage, languageString, qs);
        }
        var dateString = filterService.getQueryStringBySection(parameterNames.Dates, qsSeparator);
        if (!helperService.isNullOrEmpty(dateString)) {
            qs = queryStringService.addQueryString(parameterNames.Dates, dateString, qs);
        }
        var typeString = filterService.getQueryStringBySection(parameterNames.Types, qsSeparator);
        if (!helperService.isNullOrEmpty(typeString)) {
            qs = queryStringService.addQueryString(parameterNames.Types, typeString, qs);
        }
        var sectionString = filterService.getQueryStringBySection(parameterNames.Sections, qsSeparator);
        if (!helperService.isNullOrEmpty(sectionString)) {
            qs = queryStringService.addQueryString(parameterNames.Sections, sectionString, qs);
        }
        var topicString = filterService.getQueryStringBySection(parameterNames.Topics, qsSeparator);
        if (!helperService.isNullOrEmpty(topicString)) {
            qs = queryStringService.addQueryString(parameterNames.Topics, topicString, qs);
        }
        var professionalString = filterService.getQueryStringBySection(parameterNames.Professionals, qsSeparator);
        if (!helperService.isNullOrEmpty(professionalString)) {
            qs = queryStringService.addQueryString(parameterNames.Professionals, professionalString, qs);
        }
        var diversitygroupString = filterService.getQueryStringBySection(parameterNames.DiversityGroups, qsSeparator);
        if (!helperService.isNullOrEmpty(diversitygroupString)) {
            qs = queryStringService.addQueryString(parameterNames.DiversityGroups, diversitygroupString, qs);
        }
        var diversityString = queryStringService.getParameterByName(parameterNames.Diversity);
        if (!helperService.isNullOrEmpty(diversityString)) {
            qs = queryStringService.addQueryString(parameterNames.Diversity, diversityString, qs);
        }
        //history qs
        var historyqs = qs;
        if (isFeatured) {
            historyqs = queryStringService.removeQueryString(parameterNames.PageSize, featuredPageSize, historyqs);
        }
        else {
            historyqs = queryStringService.removeQueryString(parameterNames.PageSize, pageSize, historyqs);
        }
        historyqs = queryStringService.removeQueryString(parameterNames.SortBy, sortBy, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.SortOrder, sortOrder, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.SearchType, searchType, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.Language, language, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.NoSkip, firstSearch, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.IsFeatured, isFeatured, historyqs);
        if (pageNum <= 0) {
            historyqs = queryStringService.removeQueryString(parameterNames.PageNum, pageNum, historyqs);
        }
        history.pushState(null, document.title, historyqs.replace('?&', '?'));
        console.log(qs);
        return qs;
    };

    //start event handlers

    /* Perform Text Search
        Adds a new text filter to the applied filters on the search results

        Parameters:
            e => jQuery event object
    */
    $scope.PerformTextSearch = function (e) {
        if (!helperService.isNullOrEmpty($scope.searchText)) {
            filterService.removeBySection(parameterNames.Letter);
            filterService.removeBySection(parameterNames.Name);
            filterService.removeBySection(parameterNames.Keyword);

            if ($scope.selectedSearchBy.Id === 'search-by-keyword') {
                landingHelperService.addFilter(parameterNames.Keyword, $scope.searchText, $scope.searchText, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
            }
            else {
                landingHelperService.addFilter(parameterNames.Name, $scope.searchText, $scope.searchText, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
            }
            $scope.searchText = '';
            viewMoreClicked = false;
            pageNum = 0;
            search();
        }
    };

    /* Change Search By
    */
    $scope.ChangeSearchBy = function (e, searchBy) {
        $scope.selectedSearchBy = searchBy;
    };

    /* ToggleFilterTray
        Toggles the filter tray between open or closed

        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ToggleFilterTray = function (e, filter) {
        var initialState = filter.IsFilterTrayOpen;
        closeAllFilterTrays();
        if (!initialState) {
            filter.IsFilterTrayOpen = true;
            $scope.anyFilterTrayOpen = true;
        }
    };

    var closeAllFilterTrays = function () {
        for (var i = 0; i < $scope.filters.length; i++) {
            $scope.filters[i].IsFilterTrayOpen = false;
        }
        $scope.anyFilterTrayOpen = false;
    };

    /* Toggle Selected Option
        Toggles the Selected field value on the given Filter Option

        Parameters:
            e => jQuery event object
            option => Filter Option
    */
    $scope.ToggleSelectedOption = function (e, filter, option) {
        option.Selected = !option.Selected;
        filter.SelectedCount += option.Selected ? 1 : -1;
        filter.SelectedOption = filter.DefaultOption;
        $scope.ApplySelections(e, filter);
    };

    /* Apply Selections
        Handles clicks on each of the Apply Selections buttons to add filters to the filterService and execute a search.

        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ApplySelections = function (e, filter) {
        filterService.removeBySection(filter.QueryStringParameterName);
        filter.SelectedCount = 0;
        if (filter.Type === 0 || filter.Type === 3) {
            for (var i = 0; i < filter.Options.length; i++) {
                if (filter.Options[i].Selected) {
                    filter.SelectedCount++;
                    landingHelperService.addFilter(filter.QueryStringParameterName, filter.Options[i].Title, filter.Options[i].Id);
                }
            }
        }
        else if (filter.Type === 1) {
            for (var i = 0; i < filter.Options.length; i++) {
                if (!helperService.isNullOrEmpty(filter.Options[i].Value)) {
                    filter.SelectedCount++;
                    landingHelperService.addFilter(filter.QueryStringParameterName, filter.Options[i].Title + ' ' + filter.Options[i].Value, filter.Options[i].SortOrder + ';' + filter.Options[i].Value);
                }
            }
        }
        $scope.flatFilters = landingHelperService.getActiveFilters();
        viewMoreClicked = false;
        filter.SelectedOption = null;
        pageNum = 0;
        search();
    };

    /* Clear Selections
        Clears all selections for the filter section
        
        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ClearSelections = function (e, filter) {
        filterService.removeBySection(filter.QueryStringParameterName);
        filter.SelectedCount = 0;
        if (filter.Type === 0) {
            for (var i = 0; i < filter.Options.length; i++) {
                filter.Options[i].Selected = false;
            }
        }
        else if (filter.Type === 1) {
            for (var i = 0; i < filter.Options.length; i++) {
                filter.Options[i].Value = '';
            }
        }
        else if (filter.Type === 3) {
            filter.Options = [];
        }
        closeAllFilterTrays();
        $scope.flatFilters = landingHelperService.getActiveFilters();
        viewMoreClicked = false;
        filter.SelectedOption = null;
        pageNum = 0;
        search();
    };

    /* Close Tray
        Closes the open filter tray
    */
    $scope.CloseTray = function (e, filter) {
        closeAllFilterTrays();
    };

    /* Letter Select Handler
        Handles click on a Last Name Letter to add filter and execute a search.

        Parameters:
            e => jQuery event object
    */
    $scope.LetterSelect = function (e, filter, letter) {
        if (letter.Enabled === true) {
            filterService.removeBySection(parameterNames.Letter);
            filterService.removeBySection(parameterNames.Keyword);
            filterService.removeBySection(parameterNames.Name);

            letter.Selected = true;
            landingHelperService.addFilter(filter.QueryStringParameterName, letter.Title, letter.Title, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });

            //1 for alpha
            setActiveSortBy(1);

            viewMoreClicked = false;
            sortBy = 1;
            pageNum = 0;
            search();
        }
    };

    /* Clear Filter
        Remove a single filter from the applied filters on the search results

        Parameters:
            e => jQuery event object
            section => section value for the filter
            name => name value for the filter
            value => value for the filter
    */
    $scope.ClearFilter = function (e, section, name, value) {
        landingHelperService.removeFilter($scope.filters, section, name, value, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
        pageNum = 0;
        viewMoreClicked = false;
        search();
    };

    /* Clear Filters
        Removes all active filters applied on the search results

        Parameters:
            e => jQuery event object
    */
    $scope.ClearFilters = function (e) {
        landingHelperService.removeAllFilters($scope.filters, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
        pageNum = 0;
        viewMoreClicked = false;
        search();
    };

    /* Set Sort
        Changes the sort applied to the search results

        Parameters:
            e => jQuery event object
            name => name of the sort option
            value => value of the sort option
    */
    $scope.SetSort = function (e, sort) {
        if (sortBy !== sort.Value) {
            //change sort by
            setActiveSortBy(sort.Value);
            pageNum = 0;
            $scope.currentResultCount = 0;
            viewMoreClicked = false;
            search();
        }
    };

    function setActiveSortBy(sortValue) {
        for (var i = 0; i < $scope.sortBys.length; i++) {
            if ($scope.sortBys[i].Value === sortValue) {
                sortBy = $scope.sortBys[i].Value;
                sortOrder = $scope.sortBys[i].DefaultSortOrder;
                $scope.sortBys[i].IsSelected = true;
            }
            else {
                $scope.sortBys[i].IsSelected = false;
            }
        }
    }

    /* HyperlinkClick
        
    */
    $scope.HyperlinkClick = function (e, url, target) {
        if (!!!target || target != '_blank') {
            buildQueryString(window.pageYOffset);
            window.location = url;
        }
    };

    /* View More
        Performs another search and fetches another set of results

        Parameters:
            e => jQuery event object
    */
    $scope.ViewMore = function (e) {
        _ViewMore(true);
    };

    $scope.AutoViewMore = function (e) {
        _ViewMore();
    };

    var _ViewMore = function (bViewMoreClicked) {
        if (!$scope.isLoading) {
            scrollPosition = 0;
            if (bViewMoreClicked !== undefined) viewMoreClicked = bViewMoreClicked;
            preventAutoScroll = true;
            pageNum++;
            search();
        }
    };

    $scope.AutofillOtherDate = function (filter, option) {
        for (var i = 0; i < filter.Options.length; i++) {
            if (!filter.Options[i].Value) {
                filter.Options[i].Value = option.Value;
            }
        }
    };

    //end event handlers

    init();
}]);
