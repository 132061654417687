app.controller("EventsLanding", ['$scope', '$element', '$sce', '$timeout', 'searchService', 'filterService', 'queryStringService', 'helperService', 'landingHelperService', function ($scope, $element, $sce, $timeout, searchService, filterService, queryStringService, helperService, landingHelperService) {
    $scope.isLoading = false;
    $scope.enableInfiniteScroll = false;
    $scope.filters = [];
    $scope.flatFilters = [];
    $scope.searchBys = [];
    $scope.selectedSearchBy = null;

    $scope.currentResultCount = 0;
    $scope.totalResultCount = 0;

    $scope.hasSearchRun = false;
    $scope.searchText = '';
    $scope.searchBy = 'keyword';
    $scope.anyFilterTrayOpen = false;
    $scope.resultsLabel = '';
    $scope.enableFeatured = false;
    $scope.hasSearchRun = false;
    $scope.resultsLabel = '';

    $scope.data = {
        top: {
            currentResultCount: 0,
            totalResultCount: 0,
            hasSearchRun: false,
            isLoading: false,
            resultsLabel: '',
            searchType: 'Events',
            pageSize: 2,
            pageNum: 0,
            sortBy: 0,
            sortOrder: 1,
            name: 'top',
            list: [],
            limit: -1
        },
        bot: {
            currentResultCount: 0,
            totalResultCount: 0,
            hasSearchRun: false,
            isLoading: false,
            resultsLabel: '',
            searchType: 'Events',
            pageSize: 2,
            pageNum: 0,
            sortBy: 0,
            sortOrder: 1,
            name: 'bot',
            list: [],
            limit: -1
        }
    };

    var sanitizedFields = [];
    var parameterNames = {
        SearchType: 'searchType',
        PageSize: 'pageSize',
        PageNum: 'pageNum',
        SortBy: 'sortBy',
        SortOrder: 'sortOrder',

        TSearchType: 'tsearchType',
        TPageSize: 'tpageSize',
        TPageNum: 'tpageNum',
        TSortBy: 'tsortBy',
        TSortOrder: 'tsortOrder',

        BSearchType: 'bsearchType',
        BPageSize: 'bpageSize',
        BPageNum: 'bpageNum',
        BSortBy: 'bsortBy',
        BSortOrder: 'bsortOrder',

        ScrollPosition: 'scroll',
        Professionals: 'professionals',
        Capability: 'services',
        Location: 'offices',
        Education: 'schools',
        Admission: 'courts',
        SpokenLanguage: 'languages',
        Dates: 'dates',
        Types: 'types',
        Topics: 'topics',
        Language: 'language',
        Name: 'name',
        Keyword: 'keyword',
        Letter: 'letter',
        NoSkip: 'noSkip',
        IsFeatured: 'isFeatured'
    };
    var qsSeparator = '|';
    var firstSearch = true;

    var featuredSearchType = 'Events';
    var pastSearchType = 'PastEvents';
    var upcomingSearchType = 'UpcomingEvents';

    var pastLabel = '';
    var upcomingLabel = '';

    var pageSize = 20;
    var pageNum = 0;
    var sortBy = 0;
    var sortOrder = 0;
    var defaultSortBy = 0;
    var defaultSortOrder = 0;
    var language = 'en';
    var scrollPosition = 0;
    var isFeatured = false;
    var featuredPageSize = 5;
    var preventAutoScroll = false;

    var init = function () {
        //get any variables from html
        if ($element.attr('data-page-size')) {
            pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize);
        }
        if ($element.attr('data-upcoming-page-size')) {
            upcomingPageSize = helperService.tryParseInt($element.attr('data-upcoming-page-size'), pageSize);
        }
        if ($element.attr('data-upcoming-max')) {
            upcomingMax = helperService.tryParseInt($element.attr('data-upcoming-max'), -1);
        }
        if ($element.attr('data-top-page-size')) {
            topPageSize = helperService.tryParseInt($element.attr('data-top-page-size'), pageSize);
        }
        if ($element.attr('data-bot-page-size')) {
            botPageSize = helperService.tryParseInt($element.attr('data-bot-page-size'), pageSize);
        }

        if ($element.attr('data-sanitized-fields')) {
            sanitizedFields = $element.attr('data-sanitized-fields').split(',');
        }
        if ($element.attr('data-language')) {
            language = $element.attr('data-language');
        }
        if ($element.attr('data-default-sortBy')) {
            sortBy = $element.attr('data-default-sortBy');
            defaultSortBy = sortBy;
        }
        if ($element.attr('data-default-sortOrder')) {
            sortOrder = $element.attr('data-default-sortOrder');
            defaultSortOrder = sortOrder;
        }
        if ($element.attr('data-enable-featured')) {
            $scope.enableFeatured = $element.attr('data-enable-featured') === '1';
        }
        if ($element.attr('data-featured-page-size')) {
            featuredPageSize = helperService.tryParseInt($element.attr('data-featured-page-size'), featuredPageSize);
        }
        if ($element.attr('data-past-label')) {
            pastLabel = $element.attr('data-past-label');
        }
        if ($element.attr('data-upcoming-label')) {
            upcomingLabel = $element.attr('data-upcoming-label');
        }
        if ($element.attr('data-results-label')) {
            $scope.resultsLabel = $element.attr('data-results-label');
        }
        if (document.getElementById('json-filters') != null) {
            $scope.filters = JSON.parse(document.getElementById('json-filters').text);
        }
        if (document.getElementById('json-searchbys') != null) {
            $scope.searchBys = JSON.parse(document.getElementById('json-searchbys').text);
            $scope.selectedSearchBy = $scope.searchBys && $scope.searchBys.length > 0 ? $scope.searchBys[0] : null;
        }
        if (document.getElementById('json-sortbys') != null) {
            $scope.sortBys = JSON.parse(document.getElementById('json-sortbys').text);
        }

        if (queryStringService.hasQueryString()) {
            //if there are any query string parameters set filters, run search
            setFilters();
        }
        search();
    };

    var setFilters = function () {
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.TPageNum))) {
            $scope.data.top.pageNum = parseInt(queryStringService.getParameterByName(parameterNames.TPageNum));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.TSortBy))) {
            $scope.data.top.sortBy = parseInt(queryStringService.getParameterByName(parameterNames.TSortBy));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.TSortOrder))) {
            $scope.data.top.sortOrder = parseInt(queryStringService.getParameterByName(parameterNames.TSortOrder));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.BPageNum))) {
            $scope.data.bot.pageNum = parseInt(queryStringService.getParameterByName(parameterNames.BPageNum));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.BSortBy))) {
            $scope.data.bot.sortBy = parseInt(queryStringService.getParameterByName(parameterNames.BSortBy));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.BSortOrder))) {
            $scope.data.bot.sortOrder = parseInt(queryStringService.getParameterByName(parameterNames.BSortOrder));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.ScrollPosition))) {
            scrollPosition = parseInt(queryStringService.getParameterByName(parameterNames.ScrollPosition));
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Capability))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Capability, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Location))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Location, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Education))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Education, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Admission))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Admission, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.SpokenLanguage))) {
            landingHelperService.checkFiltersByParameter(parameterNames.SpokenLanguage, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Dates))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Dates, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Types))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Types, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Topics))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Topics, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Professionals))) {
            landingHelperService.checkFiltersByParameter(parameterNames.Professionals, $scope.filters);
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Name))) {
            var name = queryStringService.getParameterByName(parameterNames.Name);
            if (name) {
                landingHelperService.addFilter(parameterNames.Name, name, name);
            }
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Keyword))) {
            var keyword = queryStringService.getParameterByName(parameterNames.Keyword);
            if (keyword) {
                landingHelperService.addFilter(parameterNames.Keyword, keyword, keyword);
            }
        }
        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.Letter))) {
            var letter = queryStringService.getParameterByName(parameterNames.Letter);
            if (letter) {
                landingHelperService.addFilter(parameterNames.Letter, letter, letter);
            }
        }

        $scope.flatFilters = landingHelperService.getActiveFilters();
    };

    var search = function () {
        if ($scope.flatFilters.length === 0) {
            firstSearch = true;

            $scope.data.top.searchType = featuredSearchType;
            $scope.data.top.pageSize = featuredPageSize;
            $scope.data.top.resultsLabel = '';

            $scope.data.bot.searchType = upcomingSearchType;
            $scope.data.bot.pageSize = upcomingPageSize;
            $scope.data.bot.resultsLabel = upcomingLabel;
            $scope.data.bot.limit = upcomingMax;
        }
        else {
            $scope.data.top.searchType = upcomingSearchType;
            $scope.data.top.pageSize = topPageSize;
            $scope.data.top.resultsLabel = upcomingLabel;

            $scope.data.bot.searchType = pastSearchType;
            $scope.data.bot.pageSize = botPageSize;
            $scope.data.bot.resultsLabel = pastLabel;
            $scope.data.bot.limit = -1;
        }

        $scope.currentResultCount = 0;
        $scope.totalResultCount = 0;

        partialSearch($scope.data.top, false);
        partialSearch($scope.data.bot, false);

        $scope.hasSearchRun = $scope.flatFilters.length > 0;

        if (scrollPosition > 0) {
            $timeout(function () {
                window.scroll(0, scrollPosition);
            });
        }
        else if (!firstSearch && !preventAutoScroll) {
            $timeout(function () {
                var nav = document.getElementById('site-nav--js')
                if (!nav.classList.contains('site-nav--hidden')) {
                    nav.classList.add('site-nav--hidden');
                }
                document.getElementById('search-filters').scrollIntoView({ behavior: 'smooth' });
            });
        }

        firstSearch = false;
        preventAutoScroll = false;
    };

    var partialSearch = function (obj) {
        if ($scope.flatFilters.length === 0 && firstSearch) {
            obj.currentResultCount = 0;
            obj.totalResultCount = 0;
        }
        $scope.isLoading = true;
        obj.isLoading = true;

        searchService.search(
            buildQueryString(obj, 0),
            "Events",
            function (response) {
                handleSearchSuccess(obj, response);
            },
            handleSearchError);
    };

    var handleSearchSuccess = function (obj, response) {
        angular.forEach(response.data.list, function (item) {
            angular.forEach(sanitizedFields, function (name) {
                item[name] = $sce.trustAsHtml(item[name]);
            });
        });

        if (obj.pageNum > 0 && !firstSearch) {
            obj.list = obj.list.concat(response.data.list);
        }
        else {
            obj.list = response.data.list;
        }

        obj.currentResultCount += response.data.list.length;
        obj.totalResultCount = response.data.count;
        obj.hasSearchRun = true;
        obj.isLoading = false;
        if (response.data.count === 0) {
            obj.currentResultCount = 0;
        }

        $scope.currentResultCount += response.data.list.length;
        $scope.totalResultCount += response.data.count;
        $scope.hasSearchRun = true;
        $scope.isLoading = $scope.data.top.isLoading || $scope.data.bot.isLoading;

        return obj;
    };

    var handleSearchError = function (data, status, headers, config) { };

    var buildQueryString = function (obj, scroll) {
        var qs = '';
        qs = queryStringService.addQueryString(parameterNames.SearchType, obj.searchType, qs);
        qs = queryStringService.addQueryString(parameterNames.PageSize, obj.pageSize, qs);
        qs = queryStringService.addQueryString(parameterNames.PageNum, obj.pageNum, qs);
        qs = queryStringService.addQueryString(parameterNames.SortBy, obj.sortBy, qs);
        qs = queryStringService.addQueryString(parameterNames.SortOrder, obj.sortOrder, qs);
        qs = queryStringService.addQueryString(parameterNames.Language, language, qs);
        qs = queryStringService.addQueryString(parameterNames.NoSkip, firstSearch, qs);
        qs = queryStringService.addQueryString(parameterNames.IsFeatured, $scope.flatFilters.length == 0, qs);

        if (scroll && scroll > 0) {
            qs = queryStringService.addQueryString(parameterNames.ScrollPosition, scroll, qs);
        }

        var nameString = filterService.getQueryStringBySection(parameterNames.Name, ' ');
        if (!helperService.isNullOrEmpty(nameString)) {
            qs = queryStringService.addQueryString(parameterNames.Name, nameString, qs);
        }
        var keywordString = filterService.getQueryStringBySection(parameterNames.Keyword, ' ');
        if (!helperService.isNullOrEmpty(keywordString)) {
            qs = queryStringService.addQueryString(parameterNames.Keyword, keywordString, qs);
        }
        var letterString = filterService.getQueryStringBySection(parameterNames.Letter, qsSeparator);
        if (!helperService.isNullOrEmpty(letterString)) {
            qs = queryStringService.addQueryString(parameterNames.Letter, letterString, qs);
        }
        var capabilityString = filterService.getQueryStringBySection(parameterNames.Capability, qsSeparator);
        if (!helperService.isNullOrEmpty(capabilityString)) {
            qs = queryStringService.addQueryString(parameterNames.Capability, capabilityString, qs);
        }
        var locationString = filterService.getQueryStringBySection(parameterNames.Location, qsSeparator);
        if (!helperService.isNullOrEmpty(locationString)) {
            qs = queryStringService.addQueryString(parameterNames.Location, locationString, qs);
        }
        var educationString = filterService.getQueryStringBySection(parameterNames.Education, qsSeparator);
        if (!helperService.isNullOrEmpty(educationString)) {
            qs = queryStringService.addQueryString(parameterNames.Education, educationString, qs);
        }
        var admissionString = filterService.getQueryStringBySection(parameterNames.Admission, qsSeparator);
        if (!helperService.isNullOrEmpty(admissionString)) {
            qs = queryStringService.addQueryString(parameterNames.Admission, admissionString, qs);
        }
        var languageString = filterService.getQueryStringBySection(parameterNames.SpokenLanguage, qsSeparator);
        if (!helperService.isNullOrEmpty(languageString)) {
            qs = queryStringService.addQueryString(parameterNames.SpokenLanguage, languageString, qs);
        }
        var dateString = filterService.getQueryStringBySection(parameterNames.Dates, qsSeparator);
        if (!helperService.isNullOrEmpty(dateString)) {
            qs = queryStringService.addQueryString(parameterNames.Dates, dateString, qs);
        }
        var typeString = filterService.getQueryStringBySection(parameterNames.Types, qsSeparator);
        if (!helperService.isNullOrEmpty(typeString)) {
            qs = queryStringService.addQueryString(parameterNames.Types, typeString, qs);
        }
        var topicString = filterService.getQueryStringBySection(parameterNames.Topics, qsSeparator);
        if (!helperService.isNullOrEmpty(topicString)) {
            qs = queryStringService.addQueryString(parameterNames.Topics, topicString, qs);
        }
        var professionalString = filterService.getQueryStringBySection(parameterNames.Professionals, qsSeparator);
        if (!helperService.isNullOrEmpty(professionalString)) {
            qs = queryStringService.addQueryString(parameterNames.Professionals, professionalString, qs);
        }

        //history qs
        var historyqs = qs;
        historyqs = queryStringService.removeQueryString(parameterNames.SearchType, obj.searchType, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.PageSize, obj.pageSize, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.PageNum, obj.pageNum, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.SortBy, obj.sortBy, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.SortOrder, obj.sortOrder, historyqs);
        if ($scope.data.top.pageNum > 0) {
            historyqs = queryStringService.addQueryString(parameterNames.TPageNum, $scope.data.top.pageNum, historyqs);
        }
        if ($scope.data.bot.pageNum > 0) {
            historyqs = queryStringService.addQueryString(parameterNames.BPageNum, $scope.data.bot.pageNum, historyqs);
        }
        historyqs = queryStringService.removeQueryString(parameterNames.Language, language, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.NoSkip, firstSearch, historyqs);
        historyqs = queryStringService.removeQueryString(parameterNames.IsFeatured, $scope.flatFilters.length == 0, historyqs);

        history.pushState(null, document.title, historyqs.replace('?&', '?'));
        console.log(qs);
        return qs;
    };

    //start event handlers

    /* Perform Text Search
        Adds a new text filter to the applied filters on the search results

        Parameters:
            e => jQuery event object
    */
    $scope.PerformTextSearch = function (e) {
        if (!helperService.isNullOrEmpty($scope.searchText)) {
            filterService.removeBySection(parameterNames.Name);
            filterService.removeBySection(parameterNames.Keyword);

            if ($scope.searchBy === 'keyword') {
                landingHelperService.addFilter(parameterNames.Keyword, $scope.searchText, $scope.searchText, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
            }
            else {
                landingHelperService.addFilter(parameterNames.Name, $scope.searchText, $scope.searchText, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
            }
            $scope.searchText = '';
            $scope.data.top.pageNum = 0;
            $scope.data.bot.pageNum = 0;
            search();
        }
    };

    /* Change Search By
    */
    $scope.ChangeSearchBy = function (e, searchBy) {
        $scope.selectedSearchBy = searchBy;
    };

    /* ToggleFilterTray
        Toggles the filter tray between open or closed

        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ToggleFilterTray = function (e, filter) {
        var initialState = filter.IsFilterTrayOpen;
        closeAllFilterTrays();
        if (!initialState) {
            filter.IsFilterTrayOpen = true;
            $scope.anyFilterTrayOpen = true;
        }
    };

    var closeAllFilterTrays = function () {
        for (var i = 0; i < $scope.filters.length; i++) {
            $scope.filters[i].IsFilterTrayOpen = false;
        }
        $scope.anyFilterTrayOpen = false;
    };

    /* Toggle Selected Option
        Toggles the Selected field value on the given Filter Option

        Parameters:
            e => jQuery event object
            option => Filter Option
    */
    $scope.ToggleSelectedOption = function (e, filter, option) {
        option.Selected = !option.Selected;
        filter.SelectedCount += option.Selected ? 1 : -1;
        filter.SelectedOption = filter.DefaultOption;
        $scope.ApplySelections(e, filter);
    };

    /* Apply Selections
        Handles clicks on each of the Apply Selections buttons to add filters to the filterService and execute a search.

        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ApplySelections = function (e, filter) {
        filterService.removeBySection(filter.QueryStringParameterName);
        filter.SelectedCount = 0;
        if (filter.Type === 0 || filter.Type === 3) {
            for (var i = 0; i < filter.Options.length; i++) {
                if (filter.Options[i].Selected) {
                    filter.SelectedCount++;
                    landingHelperService.addFilter(filter.QueryStringParameterName, filter.Options[i].Title, filter.Options[i].Id);
                }
            }
        }
        else if (filter.Type === 1) {
            for (var i = 0; i < filter.Options.length; i++) {
                if (!helperService.isNullOrEmpty(filter.Options[i].Value)) {
                    filter.SelectedCount++;
                    landingHelperService.addFilter(filter.QueryStringParameterName, filter.Options[i].Title + ' ' + filter.Options[i].Value, filter.Options[i].SortOrder + ';' + filter.Options[i].Value);
                }
            }
        }
        $scope.flatFilters = landingHelperService.getActiveFilters();
        filter.SelectedOption = null;
        $scope.data.top.pageNum = 0;
        $scope.data.bot.pageNum = 0;
        search();
    };

    /* Clear Selections
        Clears all selections for the filter section
        
        Parameters:
            e => jQuery event object
            filter => Filter
    */
    $scope.ClearSelections = function (e, filter) {
        filterService.removeBySection(filter.QueryStringParameterName);
        filter.SelectedCount = 0;
        if (filter.Type === 0) {
            for (var i = 0; i < filter.Options.length; i++) {
                filter.Options[i].Selected = false;
            }
        }
        else if (filter.Type === 1) {
            for (var i = 0; i < filter.Options.length; i++) {
                filter.Options[i].Value = '';
            }
        }
        else if (filter.Type === 3) {
            filter.Options = [];
        }
        closeAllFilterTrays();
        $scope.flatFilters = landingHelperService.getActiveFilters();
        $scope.data.top.pageNum = 0;
        $scope.data.bot.pageNum = 0;
        search();
    };

    /* Close Tray
        Closes the open filter tray
    */
    $scope.CloseTray = function (e, filter) {
        closeAllFilterTrays();
    };

    /* Letter Select Handler
        Handles click on a Last Name Letter to add filter and execute a search.

        Parameters:
            e => jQuery event object
    */
    $scope.LetterSelect = function (e, filter, letter) {
        if (letter.Enabled === true) {
            filterService.removeBySection(parameterNames.Letter);
            for (var i = 0; i < filter.Options.length; i++) {
                filter.Options[i].Selected = false;
            }

            letter.Selected = true;
            landingHelperService.addFilter(filter.QueryStringParameterName, letter.Title, letter.Title, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });

            $scope.data.top.pageNum = 0;
            $scope.data.bot.pageNum = 0;
            search();
        }
    };

    /* Clear Filter
        Remove a single filter from the applied filters on the search results

        Parameters:
            e => jQuery event object
            section => section value for the filter
            name => name value for the filter
            value => value for the filter
    */
    $scope.ClearFilter = function (e, section, name, value) {
        landingHelperService.removeFilter($scope.filters, section, name, value, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
        $scope.data.top.pageNum = 0;
        $scope.data.bot.pageNum = 0;
        search();
    };

    /* Clear Filters
        Removes all active filters applied on the search results

        Parameters:
            e => jQuery event object
    */
    $scope.ClearFilters = function (e) {
        landingHelperService.removeAllFilters($scope.filters, function () { $scope.flatFilters = landingHelperService.getActiveFilters(); });
        $scope.data.top.pageNum = 0;
        $scope.data.bot.pageNum = 0;
        search();
    };

    /* Set Sort
        Changes the sort applied to the search results

        Parameters:
            e => jQuery event object
            name => name of the sort option
            value => value of the sort option
    */
    $scope.SetSort = function (e, sort) {
        //unused
    };

    /* HyperlinkClick
        
    */
    $scope.HyperlinkClick = function (e, searchType, url) {
        buildQueryString(searchType, pageSize, window.pageYOffset);
        window.location = url;
    };

    /* View More
        Performs another search and fetches another set of results

        Parameters:
            e => jQuery event object
    */
    $scope.ViewMore = function (e, obj) {
        preventAutoScroll = true;
        obj.pageNum++;
        $scope.totalResultCount -= obj.totalResultCount;
        partialSearch(obj);
    };

    $scope.ShowViewMore = function (obj) {
        if (obj.limit > 0) {
            return obj.totalResultCount > obj.currentResultCount && obj.currentResultCount < obj.limit;
        }
        else {
            return obj.totalResultCount > obj.currentResultCount;
        }
    }

    $scope.AutofillOtherDate = function (filter, option) {
        for (var i = 0; i < filter.Options.length; i++) {
            if (!filter.Options[i].Value) {
                filter.Options[i].Value = option.Value;
            }
        }
    };

    //end event handlers

    init();
}]);