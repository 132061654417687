
app.controller('SiteSearch', ['$scope', '$http', '$element', '$sce', 'queryStringService', function ($scope, $http, $element, $sce, queryStringService) {
    $scope.keyword = "";
    $scope.activeKeyword = "";
    $scope.filterByOption = 0;
    $scope.hasMore = false;
    $scope.hasSearched = false;
    $scope.isLoading = true;
    $scope.isLoadingMore = false;
    $scope.skip = 0;
    $scope.pageNum = 0;
    $scope.resultsCount = 0;
    $scope.numberOfResultsPerPage = 0;
    $scope.trustAsHtml = $sce.trustAsHtml;
    $scope.resultsCount = 0;
    $scope.category = 0;
    $scope.filters = [];
    $scope.selectedfilters = [];
    $scope.resultsLabel = $element.attr('data-results-label');
    $scope.allFlag = false;


    var toggleCategory = function (newValue) {
        $scope.category = newValue;
        $scope.performSearch(true);
    };

    $scope.init = function (initialJson) {
        setValues(initialJson);
        if (document.getElementById('json-filters') != null) {
            $scope.filters = JSON.parse(document.getElementById('json-filters').text);
        }
        if (queryStringService.getParameterByName("keyword") == '') {
            $scope.hasSearched = false;
        }
    };

    var setValues = function (initialJson) {
        var json = JSON.parse(initialJson);
        $scope.hasSearched = "searchInformation" in json;
        $scope.activeKeyword = queryStringService.getParameterByName("keyword");
        $scope.keyword = $scope.activeKeyword;
        $scope.category = !!queryStringService.getParameterByName("category") ? queryStringService.getParameterByName("category") : 0;
        if (!$scope.isLoadingMore) {
            $scope.results = json.items;
        }
        $scope.hasMore = "nextPage" in json.queries && json.queries.nextPage != null;
        $scope.isLoadingMore = false;
        $scope.resultsCount = parseInt(json.searchInformation.totalResults);
    };

    $scope.performSearch = function (hasSearched, isLoadMore) {
        if (hasSearched)
            $scope.hasSearched = hasSearched;

        $scope.isLoading = true;

        if (isLoadMore) {
            $scope.isLoadingMore = true;
            $scope.pageNum++;
            $scope.skip = $scope.pageNum * $element.attr('data-page-size');
        }
        else {
            $scope.skip = 0;
            $scope.isLoadingMore = false;
            $scope.pageNum = 0;
        }

        var queryString = "";
        queryString = queryStringService.addQueryString('skip', $scope.skip, queryString);
        queryString = queryStringService.addQueryString('hassearched', $scope.hasSearched, queryString);
        if ($scope.keyword !== "")
            queryString = queryStringService.addQueryString('keyword', encodeURIComponent($scope.keyword), queryString);
        if ($scope.selectedfilters.length > 0) {
            queryString = queryStringService.addQueryString('category', $scope.selectedfilters.map(function (a) { return a.Value; }).join('|'), queryString);
        }

        //Set has searched to false if there is no search criteria
        if (!$scope.keyword) {
            $scope.hasSearched = false;
            hasSearched = false;
        }

        //If user has performed a search action - i.e. Keyword, Filter, etc. (besides load more)
        if (hasSearched) {
            queryString = queryStringService.addQueryString('hassearched', $scope.hasSearched, queryString);
        } else {
            queryString = queryStringService.addQueryString('hassearched', false, queryString);
            $scope.hasSearched = false;
        }

        //Push state
        history.pushState(null, document.title, queryString.replace('?&', '?'));
        doSearch();
    };

    var doSearch = function () {
        $http.get('/api/sitecore/sitesearch/search' + window.location.search).success(function (response) {
            var responseJSON = JSON.parse(JSON.parse(response));

            if ($scope.isLoadingMore) {
                $scope.results = $scope.results.concat(responseJSON.items);
            } else {
                $scope.results = responseJSON.items;
            }

            //Reinitialize values
            setValues(JSON.parse(response));

            //$scope.activeKeyword = $scope.keyword;
            //$scope.isLoadingMore = false;
            $scope.isLoading = false;
            //$scope.hasSearched = $scope.activeKeyword.length > 0;
        }).error(function (data, status, headers, config) {

        });

    };

    var applyFilters = function () {
        $scope.selectedfilters = [];
        $scope.filters.forEach(function (filter) {
            if (filter.Selected == true && filter.Title != "All") {
                $scope.selectedfilters.push(filter);
            }
        });
        allFilter();
        $scope.performSearch(true, false);
    };

    var allFilter = function(){
        var allFilter = null;
        $scope.filters.forEach(function (filter) {
            if (filter.Title == "All")
            {
                 allFilter = filter;
            }           
        });
        if($scope.allFlag){
            $scope.filters.forEach(function (filter) {
                if(filter.Title != "All")
                {
                    filter.Selected = false;
                }
            });
            $scope.selectedfilters = [];
            $scope.selectedfilters.push(allFilter);
        }
        else if(allFilter.Selected == true && $scope.selectedfilters.length > 0){
            allFilter.Selected = false
        }else if($scope.selectedfilters.length == 0){
            allFilter.Selected = true;
            $scope.selectedfilters.push(allFilter);
        }
        $scope.allFlag = false;
        };

    $scope.clearAllFilters = function () {
        $scope.selectedfilters = [];
        for (var i = 0; i < $scope.filters.length; i++) {
            var filter = $scope.selectedfilters[i];
            filter.Selected = false;
        }
        $scope.performSearch(true, false);
    };

    $scope.removeFilter = function (filter) {
        filter.Selected = false;
        var index = $scope.selectedfilters.indexOf(filter);
        if (index > -1) {
            $scope.selectedfilters.splice(index, 1);
        }
        $scope.performSearch(true, false);
    };

    $scope.setSelected = function (filter) {
    if(filter.Title == "All"){  
        $scope.allFlag = true;
    }
        filter.Selected = !filter.Selected;
        applyFilters();
    };

    $scope.setKeyword = function (keyword) {
        $scope.keyword = keyword;
        $scope.performSearch(true, false);
    };

    $scope.clearInput = function () {
        $scope.keyword = '';
        $scope.performSearch(false, false);
    }

    var parseQueryString = function (str) {
        if (typeof str !== 'string') {
            return {};
        }
        str = str.trim().replace(/^\?/, '');
        if (!str) {
            return {};
        }
        return str.trim().split('&').reduce(function (ret, param) {
            var parts = param.replace(/\+/g, ' ').split('=');
            ret[parts[0]] = parts[1] === undefined ? null : decodeURIComponent(parts[1]);
            return ret;
        }, {});
    };
}]);
