app.directive('ngTypeahead', ['$timeout', '$window', 'searchService', 'queryStringService', function ($timeout, $window, searchService, queryStringService) {
    var controller = function ($scope, $element) {
        var timeout;

        function init() {
            $scope.active = false;
            $scope.inProgress = false;
            $scope.options = [];
            $scope.placeholder = '';
            $scope.query = '';
            $scope.unique = $scope.$id;
            $scope.elem = $element;
        };

        init();

        // keycodes:
        // tab:             9
        // enter:           13
        // esc:             27
        // arrow left:      37
        // arrow up:        38
        // arrow right:     39
        // arrow down:      40
        $scope.handleKeyup = function ($event, callback) {
            var name = $event.currentTarget.name;
            var currentIndex = parseInt($event.currentTarget.attributes['data-order'].value);

            switch ($event.keyCode) {
                case 9:
                case 40:
                    //go to next item in list
                    var nextIndex = currentIndex + 1;
                    if (nextIndex <= $scope.options.length) {
                        var nextElem = $scope.elem.find('[data-order="' + nextIndex + '"]');
                        if (nextElem) {
                            nextElem.focus();
                        }
                    }
                    break;
                case 13:
                    //if currently selecting a list item, trigger a click
                    if (currentIndex > 0) {
                        $timeout(function () {
                            angular.element($event.currentTarget).triggerHandler('click');
                        });
                    }
                    break;
                case 27:
                    //close the typeahead list
                    $scope.active = false;
                    break;
                case 37:
                case 39:
                    //do nothing
                    break;
                case 38:
                    //go to prev item in list
                    var prevIndex = currentIndex - 1;
                    if (prevIndex >= 0) {
                        var prevElem = $scope.elem.find('[data-order="' + prevIndex + '"]');
                        prevElem.focus();
                    }
                    break;
                default:
                    if (callback) callback();
                    break;
            }
        };

        $scope.handleKeydown = function ($event) {
            switch ($event.keyCode) {
                case 9:
                case 13:
                case 27:
                case 37:
                case 38:
                case 39:
                case 40:
                    $event.preventDefault();
                    break;
            }
        };

        $scope.handleInputFocus = function () {
            if ($scope.options.length > 0) {
                $scope.active = true;
            }
        };

        $scope.getTypeaheadList = function () {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
                _getTypeaheadList();
            }, 300);
        };

        function _getTypeaheadList() {
            if ($scope.query.length >= 3) {
                $scope.active = true;
                $scope.inProgress = true;

                var exclude = '';

                var qs = '';
                qs = queryStringService.addQueryString('searchType', $scope.searchType, qs);
                qs = queryStringService.addQueryString('name', $scope.query, qs);
                qs = queryStringService.addQueryString('pageSize', 10, qs);
                qs = queryStringService.addQueryString('sortBy', 1, qs);
                qs = queryStringService.addQueryString('sortOrder', 0, qs);
                if (exclude !== '') {
                    qs = queryStringService.addQueryString('exclude', exclude, qs);
                }

                console.log(qs);

                searchService.search(qs, $scope.searchType, handleSearchSuccess, handleSearchError);
            }
            else {
                $scope.active = false;
                $scope.options = [];
            }
        };

        function handleSearchSuccess(response) {
            $scope.options = response.data.list;
            $scope.inProgress = false;
        };

        function handleSearchError() {
            $scope.options = [];
            $scope.inProgress = false;
        };

        $scope.addSelection = function (option) {
            if ($scope.selectCallback) {
                var skip = false;
                for (var i = 0; i < $scope.filter.Options.length; i++) {
                    if ($scope.filter.Options[i].Id === option.EntityId) {
                        skip = true;
                        break;
                    }
                }
                if (!skip) {
                    var opt = {
                        Id: option.EntityId,
                        Title: option.EntityTitle,
                        Enabled: true,
                        SortOrder: $scope.filter.Options.length,
                        Selected: false,
                        Value: null
                    };
                    $scope.filter.Options.push(opt);
                    $scope.selectCallback(null, $scope.filter, opt);
                }
            }
            $scope.query = '';
            $scope.options = [];
            $scope.active = false;
        }
    },

    link = function ($scope, $element, $attrs) {
        angular.element($window).on('click', function (event) {
            if ($element.has(event.srcElement || event.target).length === 0) {
                $scope.inProgress = false;
                $scope.active = false;
                $scope.$apply();
            }
        });
    },

    template = '<div class="filter-section-typeahead-container">' +
               '<input placeholder="{{ filter.PlaceholderText }}" type="text" class="filter-section-input" ng-model="query" ng-keydown="handleKeydown($event)" ng-keyup="handleKeyup($event, getTypeaheadList)" name="{{ unique }}-typeahead-input" data-order="0" ng-focus="handleInputFocus()" />' +
               '<div class="typeahead-list" ng-class="{ active : active == true }">' +
               '<div class="typeahead-list-inprogress" ng-show="inProgress == true"><span class="typeahead-inprogress">{{ progressMessage }}</span></div>' +
               '<button type="button" class="typeahead-list-item" ng-show="inProgress == false && options.length > 0" ng-repeat="option in options" ng-keydown="handleKeydown($event)" ng-keyup="handleKeyup($event, null)" ng-click="addSelection(option)" data-order="{{ $index + 1 }}" name="{{ unique }}-typeahead-item-{{ $index + 1 }}" tab-index="{{ $index + 1 }}">{{ option.EntityTitle }}</button>' +
               '<div class="typeahead-list-empty" ng-show="inProgress == false && options.length == 0">{{ emptyMessage }}</div>' +
               '</div>' +
               '</div>';

    controller.$inject = ["$scope", "$element"];

    return {
        replace: true,
        restrict: 'A',
        scope: {
            query: '=taModel',
            filter: '=taFilter',
            selectCallback: '=taSelectCallback',
            searchType: '@taSearchType',
            progressMessage: '@taProgressMessage',
            emptyMessage: '@taEmptyMessage',
        },
        controller: controller,
        template: template,
        link: link
    };
}]);