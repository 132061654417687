app.directive('jqdatepicker',[function () {
    var controller = function ($scope, $element) {
        function init() { }
        init();
    },
      link = function ($scope, $element, $attrs, ctrl) {
        $element.datepicker({
            dateFormat: 'mm/dd/yy',
            onSelect: function (date) {
                ctrl.$setViewValue(date);
                ctrl.$render();
                $scope.$apply();
            }
        });
        if ($scope.placeholder) $element.attr('placeholder', $scope.placeholder);
    };

    controller.$inject = ["$scope", "$element"];

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            placeholder: '=jqdpPlaceholder'
        },
        controller: controller,
        link: link
    };
}]);