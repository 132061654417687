app.filter('trustAsHtml', ['$sce', function ($sce) {
    return function (html) {
        if (typeof html === 'string') {
            return $sce.trustAsHtml(html);
        }
        else {
            return html;
        }
    };
}]);
