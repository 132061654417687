app.filter('filterByTypes', function () {
    return function (input, values) {
        var out = [];

        angular.forEach(input, function (item) {
            angular.forEach(values, function (value) {
                if (item.Type === value) {
                    out.push(item);
                }
            });
        });

        return out;
    }
});