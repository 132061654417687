app.factory('searchService', ['$http', function ($http) {
    return {
        search: function (query, searchType, success, error) {
            $http.get('/sitecore/api/ssc/webapi/search/1/' + searchType + '/' + query)
            .success(function (response) {
                success(response);
            })
            .error(function (data, status, headers, config) {
                error(data, status, headers, config);
            });
        },
    };
}]);