app.controller('AlumniSchoolList', ['$scope', '$http', '$element', '$window', '$sce', function ($scope, $http, $element, $window, $sce) {
    var query = '';
    var sanitizedFields = [];
    var $dropDown;

    $dropDown = $('.btn-link');

    $scope.list = [];

    var init = function () {
        if ($element.attr('data-query')) {
            query = $element.attr('data-query');
        }
        if ($element.attr('data-sanitized-fields')) {
            sanitizedFields = $element.attr('data-sanitized-fields').split(',');
        }

        if (document.getElementById('data-init') != null && document.getElementById('data-init').text.length > 0) {
            $scope.list = JSON.parse(document.getElementById('data-init').text);
        }

        if ($scope.list.length === 0) {
            load();
        }
    };

    var load = function () {
        $http.get('/sitecore/api/ssc/webapi/alumnisearch/1/GetAlumniSchools/' + query)
            .success(function (response) {
                if (response.Success) {
                    angular.forEach(response.List, function (item) {
                        angular.forEach(sanitizedFields, function (name) {
                            item[name] = $sce.trustAsHtml(item[name]);
                        });
                    });

                    $scope.list = response.List;
                }
            })
            .error(function (data, status, headers, config) {

            });
    };

    init();

    $scope.performSearch = function () {
        $window.location.href = $scope.alumniSelect;
    };

    if ($dropDown.length) {

        $body = $('body');

        $body.on('click', '.btn-link', function toggleDropDown() {

            var outsideClick,
                $this;

            $this = $(this).parent();
            $this.toggleClass('show-options');

            outsideClick = function (e) {
                if ($(e.target).closest('.drop-down')[0] !== $this[0]) {
                    $this.removeClass('show-options');
                }
            }
        })
    };
}]);