app.controller('SiteSearchHeader', ['$scope', '$http', 'queryStringService', function ($scope, $http, queryStringService) {
    $scope.keyword = "";
    $scope.typeAheadResults = [];

    var mouse_is_inside = false;
    var headerForm = null;

    var init = function () {
        headerForm = $('#headerSearch');
    }

    $scope.typeAheadKeyup = function () {
        if ($scope.keyword.length >= 3) {
            performTypeAheadSearch();
        }
        else {
            $scope.typeAheadResults = [];
        }
    };

    var performTypeAheadSearch = function () {
        $scope.typeAheadResults = [];

        var queryString = "";
        if ($scope.keyword !== "") {
            queryString = queryStringService.addQueryString('keyword', $scope.keyword, queryString);
        }

        $http.get('/api/sitecore/sitesearch/typeahead' + queryString).success(function (response) {
            var responseJSON = response.splice(1, 6);
            if (responseJSON.length > 0) {
                for (var i = 0; i < responseJSON.length; i++) {
                    responseJSON[i] = responseJSON[i].replace("[", "").replace("]", "").replace(/['"]+/g, '').replace("\"", "").split(',')[0];
                    if (responseJSON[i] == "") {
                        responseJSON.splice(i, 1);
                    }
                }
                $scope.typeAheadResults = responseJSON;
            }
        }).error(function (data, status, headers, config) {
            $scope.typeAheadResults = [];
        });
    };

    $scope.setKeywordHeader = function (keyword) {
        $scope.keyword = keyword;
        setTimeout(function () {
            if (headerForm) headerForm.submit();
        }, 700);
    };

    $(document).ready(function () {
        $('.type-ahead-container').hover(function () {
            mouse_is_inside = true;
        }, function () {
            mouse_is_inside = false;
        });

        $('body').mouseup(function () {
            if (!mouse_is_inside) $scope.typeAheadResults = [];
        });

        init();
    });
}]);