app.directive('ngaccordion', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var timing = 200;
            var openClass = 'open';
            var contentSelector = '.accordion-content';
            var triggerSelector = '.accordion-trigger button';
            var containerSelector = '.accordion-container';
        
            element.find(triggerSelector).click(function (e, o) {
                var $accordionContent = element.find(contentSelector);
                var $accordionContainer = element.find(containerSelector);
                
                //handle expand all functionality located in site.js
                if (o) {
                    if (o.isClickExpandAll) {
                        element.addClass(openClass);
                    }
                    else {
                        element.removeClass(openClass);
                    }
                }
                else {
                    element.toggleClass(openClass);
                }

                if (element.hasClass(openClass)) {
                    $accordionContainer.animate({
                        height: $accordionContent.height() + 60
                    }, timing);
                }
                else {
                    $accordionContainer.animate({
                        height: 0
                    }, timing);
                }
            });
        }
    }
});