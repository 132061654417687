app.controller('Header', ['$scope', '$element', 'queryStringService', function ($scope, $element, queryStringService) {

    var History = window.history;
    $scope.languages = JSON.parse($element.attr('data-languages'));
    $scope.changeLanguage = function (cultureCode) {

        var currentUrl = window.location.href;
        if (currentUrl.indexOf("/" + cultureCode + "/") != -1)
            return;

        //build url
        //first look to see if the culture code exists already
        for (i = 0; i < $scope.languages.length; i++) {
            if (currentUrl.indexOf("/" + $scope.languages[i] + "/") != -1) {  //language code needs to be replaced
                window.location.href = currentUrl.replace("/" + $scope.languages[i] + "/", "/" + cultureCode + "/");
                return;
            }
        }
        //if we make it here no culture code is in the url so we need to add it 
        var hostName = location.hostname;
        window.location.href = currentUrl.replace(hostName + "/", hostName + "/" + cultureCode + "/");
    }


    var activeLanguage = queryStringService.getParameterByName('language');
    if (activeLanguage != "") {
        $scope.changeLanguage(activeLanguage, false);
    }
}]);