app.directive('ngLazyshow', function () {
    return {
        restrict: 'AC',
        link: function (scope, element, attrs, controller, transcludeFn) {
            var timing = 100;

            var check = function () {
                var inview = update(element.get(0));

                if (!inview) {
                    setTimeout(check, timing);
                }
            };

            var update = function (elem) {
                var clientRect = elem.getBoundingClientRect();
                var isPartiallyInView = clientRect.top < window.innerHeight && clientRect.top >= 0;
                var isScrolledPast = clientRect.top < clientRect.height;
                var inViewClass = 'lazy-show--is-in-view';

                if (isScrolledPast || isPartiallyInView) {
                    elem.classList.add(inViewClass);
                    return true;
                }
                else {
                    return false;
                }
            };

            setTimeout(check, timing);
        }
    }
});