app.controller('NewsInsightsAndEvents', ['$scope', '$http', '$element', 'helperService', 'queryStringService', function ($scope, $http, $element, helperService, queryStringService) {
    $scope.relatedID = $element.attr('data-related-id');
    $scope.pageType = $element.attr('data-page-type');
    $scope.language = $element.attr('data-language');
    $scope.searchType = '';
    $scope.pageSize = 4;
    $scope.count = 0;
    $scope.pageNum = 0;
    $scope.featured = [];
    $scope.insights = [];
    $scope.news = [];
    $scope.pastEvents = [];
    $scope.upcomingEvents = [];
    $scope.searchComplete = false;
    $scope.isLoading = false;
    $scope.sortOrder = 1;
    $scope.sortBy = 0;
    $scope.hasMore = false;
    $scope.initialFeatured = [];
    $scope.isInitialSearch = true;
    $scope.featuredHasMore = false;
    $scope.insightsHasMore = false;
    $scope.newsHasMore = false;
    $scope.pastEventsHasMore = false;
    $scope.upcomingEventsHasMore = false;
    $scope.initialFeaturedHasMore = false;
    $scope.initialInsightsHasMore = false;
    $scope.initialNewsHasMore = false;
    $scope.initialPastEventsHasMore = false;
    $scope.initialUpcomingEventsHasMore = false;
    $scope.featuredPageSize = 4;
    $scope.insightsPageSize = 4;
    $scope.newsPageSize = 4;
    $scope.eventsPageSize = 4;
    var History = window.history;

    $scope.init = function () {
    };

    $scope.changeTabs = function () {
        if ($scope.featured != null)
        {
            $scope.featured = $scope.featured.splice(0, $scope.featuredPageSize);
            $scope.featuredHasMore = $scope.initialFeaturedHasMore;
        }
        if ($scope.insights != null)
        {
            $scope.insights = $scope.insights.splice(0, $scope.insightsPageSize);
            $scope.insightsHasMore = $scope.initialInsightsHasMore;
        }
        if ($scope.news != null)
        {
            $scope.news = $scope.news.splice(0, $scope.newsPageSize);
            $scope.newsHasMore = $scope.initialNewsHasMore;
        }
        if ($scope.pastEvents != null)
        {
            $scope.pastEvents = $scope.pastEvents.splice(0, $scope.eventsPageSize);
            $scope.pastEventsHasMore = $scope.initialPastEventsHasMore;
        }
        if ($scope.upcomingEvents != null) {
            $scope.upcomingEvents = $scope.upcomingEvents.splice(0, $scope.eventsPageSize);
            $scope.upcomingEventsHasMore = $scope.initialUpcomingEventsHasMore;
        }

        $scope.pageNum = 0;
        $scope.isLoadingMore = false;
    }

    $scope.setValues = function(jsonModel)
    {
        if (jsonModel.Featured != null) {
            setFeaturedValues(jsonModel.Featured);
            $scope.featuredHasMore = jsonModel.FeaturedHasMore;
            $scope.initialFeaturedHasMore = jsonModel.FeaturedHasMore;
        }
        if (jsonModel.Insights != null) {
            setInsightsValues(jsonModel.Insights);
        }
        if (jsonModel.News != null) {
            setNewsValues(jsonModel.News);
        }
        if (jsonModel.PastEvents != null) {
            setPastEventsValues(jsonModel.PastEvents);
        }
        if (jsonModel.UpcomingEvents != null) {
            setUpcomingEventsValues(jsonModel.UpcomingEvents);
        }
        if (jsonModel.FeaturedPageSize != null) {
            $scope.featuredPageSize = jsonModel.FeaturedPageSize;
        }
        if (jsonModel.InsightsPageSize != null) {
            $scope.insightsPageSize = jsonModel.InsightsPageSize;
        }
        if (jsonModel.NewsPageSize != null) {
            $scope.newsPageSize = jsonModel.NewsPageSize;
        }
        if (jsonModel.EventsPageSize != null) {
            $scope.eventsPageSize = jsonModel.EventsPageSize;
        }
    };

    function setFeaturedValues(jsonResult) {
        if ($scope.isLoadingMore) {
            $scope.featured = $scope.featured.concat(jsonResult);
            $scope.isLoadingMore = false;
        } else {
            if (!($scope.initialFeatured > 0)) {
                $scope.initialFeatured = $scope.initialFeatured.concat(jsonResult);
            }
            $scope.featured = jsonResult;
            $scope.featured = $scope.featured.slice(0, $scope.featuredPageSize);
        }
    }

    function setInsightsValues(jsonResult) {
        if ($scope.isLoadingMore) {
            $scope.insights = $scope.insights.concat(jsonResult);
            $scope.isLoadingMore = false;
        } else {
            $scope.insights = jsonResult.InsightsSearchResults;
            $scope.initialInsightsHasMore = jsonResult.HasMoreResults;
        }
        $scope.insightsHasMore = jsonResult.HasMoreResults;
    }

    function setNewsValues(jsonResult) {
        if ($scope.isLoadingMore) {
            $scope.news = $scope.news.concat(jsonResult);
            $scope.isLoadingMore = false;
        } else {
            $scope.news = jsonResult.NewsSearchResults;
            $scope.initialNewsHasMore = jsonResult.HasMoreResults;
        }
        $scope.newsHasMore = jsonResult.HasMoreResults;
    }

    function setPastEventsValues(jsonResult) {
        if ($scope.isLoadingMore) {
            $scope.pastEvents = $scope.pastEvents.concat(jsonResult);
            $scope.isLoadingMore = false;
        } else {
            $scope.pastEvents = jsonResult.EventsSearchResults;
            $scope.initialPastEventsHasMore = jsonResult.HasMoreResults;
        }
        $scope.pastEventsHasMore = jsonResult.HasMoreResults;
    }

    function setUpcomingEventsValues(jsonResult) {
        if ($scope.isLoadingMore) {
            $scope.upcomingEvents = $scope.upcomingEvents.concat(jsonResult);
            $scope.isLoadingMore = false;
        } else {
            $scope.upcomingEvents = jsonResult.EventsSearchResults;
            $scope.initialUpcomingEventsHasMore = jsonResult.HasMoreResults;
        }
        $scope.upcomingEventsHasMore = jsonResult.HasMoreResults;
    }

    $scope.Search = function () {
        $scope.isLoading = true;

        BuildQueryString();
        $http.get('/sitecore/api/ssc/webapi/search/1/' + $scope.searchType + '/' + $scope.queryString).success(function (response) {
            angular.forEach(response.data.list, function (item) {
                angular.forEach($scope.sanitizeFields, function (fieldName) {
                    var sanitized = $sce.trustAsHtml(item[fieldName]);
                    item[fieldName] = sanitized;
                });
            });

                switch ($scope.searchType) {
                    case 'Featured':
                        setFeaturedValues(response.data.list);
                        break;
                    case 'Insights':
                        setInsightsValues(response.data.list);
                        $scope.insightsHasMore = response.data.count > $scope.insights.length;
                        break;
                    case 'News':
                        setNewsValues(response.data.list);
                        $scope.newsHasMore = response.data.count > $scope.news.length;
                        break;
                    case 'PastEvents':
                        setPastEventsValues(response.data.list);
                        $scope.pastEventsHasMore = response.data.count > $scope.pastEvents.length;
                        break;
                    case 'UpcomingEvents':
                        setUpcomingEventsValues(response.data.list);
                        $scope.upcomingEventsHasMore = response.data.count > $scope.upcomingEvents.length;
                        break;
                }

            $scope.count = response.data.count;
            $scope.searchComplete = true;
            $scope.isInitialSearch = false;
            $scope.isLoading = false;
            $scope.isLoadingMore = false;
        }).error(function (data, status, headers, config) {

        });
    };

    $scope.isNullOrEmpty = function (value) {
        return helperService.isNullOrEmpty(value);
    };

    $scope.nextPage = function (searchType) {
        if (!$scope.isLoadingMore) {
            $scope.pageNum++;
            $scope.searchType = searchType;
            $scope.isLoadingMore = true;
            if (searchType != 'Featured') {
                $scope.Search();
            }
            else {
                var temp = [];
                temp = temp.concat($scope.initialFeatured);
                $scope.featured = $scope.featured.concat(temp.slice(($scope.pageNum * $scope.pageSize), (($scope.pageNum + 1) * $scope.pageSize)));
                $scope.count = $scope.initialFeatured.length;
                $scope.searchComplete = true;
                $scope.isLoadingMore = false;
                $scope.isInitialSearch = false;
                $scope.featuredHasMore = ($scope.initialFeatured.length > $scope.featured.length);
            }
        }
    };
    function BuildQueryString() {
        $scope.queryString = "";
        $scope.queryString = queryStringService.addQueryString('pageSize', $scope.pageSize, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('pageNum', $scope.pageNum, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('searchType', $scope.searchType, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortBy', $scope.sortBy, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('sortOrder', $scope.sortOrder, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('language', $scope.language, $scope.queryString);
        $scope.queryString = queryStringService.addQueryString('noSkip', false, $scope.queryString);

        if ($scope.pageType == 'Offices') {
            $scope.queryString = queryStringService.addQueryString('offices', $scope.relatedID, $scope.queryString);
        }
        else if ($scope.pageType == 'Services') {
            $scope.queryString = queryStringService.addQueryString('services', $scope.relatedID, $scope.queryString);
        }
        //professionals
        else if ($scope.pageType == 'Professionals'){
            $scope.queryString = queryStringService.addQueryString('professionals', $scope.relatedID, $scope.queryString);
        }
        else if ($scope.pageType == 'Topics') {
            $scope.queryString = queryStringService.addQueryString('topics', $scope.relatedID, $scope.queryString);
        }
        else {
            $scope.queryString = queryStringService.addQueryString('related', $scope.relatedID, $scope.queryString);
        }

        //HISTORY QUERY STRING
        var updatedQueryString = queryStringService.removeQueryString('pageSize', $scope.pageSize, $scope.queryString);
        updatedQueryString = queryStringService.removeQueryString('sortOrder', $scope.sortOrder, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('sortBy', $scope.sortBy, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('loadAllByPageSize', $scope.isInitialSearch, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('isInsightsPage', true, updatedQueryString);
        updatedQueryString = queryStringService.removeQueryString('isInsightsPage', false, updatedQueryString);

        if ($scope.pageNum <= 0)
            updatedQueryString = queryStringService.removeQueryString('pageNum', $scope.pageNum, updatedQueryString);

        console.log($scope.queryString);
    }
}]);