app.factory('landingHelperService', ['filterService', 'queryStringService', function (filterService, queryStringService) {
    var parameterNames = {
        Keyword: 'keyword',
        Name: 'name'
    };
    var _getActiveFilters = function () {
        var returnval = [];

        var keywords = [];
        var others = [];

        var sections = filterService.get();
        for (var i = 0; i < sections.length; i++) {
            for (var j = 0; j < sections[i].values.length; j++) {
                var obj = { section: sections[i].sectionName, name: sections[i].values[j].filterName, value: sections[i].values[j].filterValue };
                if (sections[i].sectionName === parameterNames.Keyword || sections[i].sectionName === parameterNames.Name) {
                    keywords.push(obj);
                }
                else {
                    others.push(obj);
                }
            }
        }
        for (var x = 0; x < keywords.length; x++) {
            returnval.push(keywords[x]);
        }
        for (var y = 0; y < others.length; y++) {
            returnval.push(others[y]);
        }

        return returnval;
    };
    var _checkFiltersByParameter = function (parameter, filters) {
        var v = queryStringService.getParameterByName(parameter);
        if (v) {
            var vs = v.split('|');
            if (vs) {
                for (var i = 0; i < filters.length; i++) {
                    if (filters[i].QueryStringParameterName === parameter) {
                        for (var k = 0; k < vs.length; k++) {
                            if (filters[i].Type === 1) {
                                //dates
                                var parts = vs[k].split(';');
                                if (parts.length === 2) {
                                    var id = parts[0];
                                    var value = parts[1];
                                    for (var j = 0; j < filters[i].Options.length; j++) {
                                        if (id == filters[i].Options[j].SortOrder) {
                                            filters[i].Options[j].Value = value;
                                            filters[i].SelectedCount++;

                                            var section = parameter;
                                            var name = filters[i].Options[j].Title + ' ' + filters[i].Options[j].Value;
                                            var value = filters[i].Options[j].SortOrder + ';' + filters[i].Options[j].Value;
                                            _addFilter(section, name, value);
                                        }
                                    }
                                }
                            }
                            else {
                                for (var j = 0; j < filters[i].Options.length; j++) {
                                    if (vs[k] === filters[i].Options[j].Id) {
                                        filters[i].Options[j].Selected = true;
                                        filters[i].SelectedCount++;

                                        var section = parameter;
                                        var name = filters[i].Options[j].Title;
                                        var value = filters[i].Options[j].Id;
                                        _addFilter(section, name, value);
                                    }
                                }
                            }
                        }
                        break;
                    }
                }
            }
        }
    };
    var _addFilter = function (section, name, value, callback) {
        var filter = { sectionName: section, name: name, value: value };
        if (!filterService.filterExists(filter)) {
            filterService.add(filter);
        }
        if (callback) callback();
    };
    var _removeFilter = function (filters, section, name, value, callback) {
        for (var i = 0; i < filters.length; i++) {
            if (filters[i].QueryStringParameterName === section) {
                for (var j = 0; j < filters[i].Options.length; j++) {
                    if (filters[i].Type === 1 && value === (filters[i].Options[j].SortOrder + ';' + filters[i].Options[j].Value)) {
                        filters[i].Options[j].Value = '';
                        filters[i].Options[j].Selected = false;
                        filters[i].SelectedCount--;
                        break;
                    }
                    else if (filters[i].Options[j].Id === value) {
                        filters[i].Options[j].Selected = false;
                        filters[i].SelectedCount--;
                        break;
                    }
                }
            }
        }
        var filter = { sectionName: section, name: name, value: value };
        if (filterService.filterExists(filter)) {
            filterService.remove(filter);
        }
        if (callback) callback();
    };
    var _removeAllFilters = function (filters, callback) {
        for (var i = 0; i < filters.length; i++) {
            filters.SelectedCount = 0;
            for (var j = 0; j < filters[i].Options.length; j++) {
                filters[i].Options[j].Selected = false;
            }
        }
        filterService.clear();
        if (callback) callback();
    };
    return {
        getActiveFilters: _getActiveFilters,
        checkFiltersByParameter: _checkFiltersByParameter,
        addFilter: _addFilter,
        removeFilter: _removeFilter,
        removeAllFilters: _removeAllFilters,
    };
}]);