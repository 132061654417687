app.controller('ContactUsController', ['$scope', '$http', '$element', 'queryStringService', function ($scope, $http, $element, queryStringService) {
    $scope.success = false;

    $scope.errorMessage = '';
    $scope.successMessage = $element.attr('data-successmessage');
    $scope.failureMessage = $element.attr('data-failuremessage');
    $scope.recaptchaFailedMessage = $element.attr('data-recaptchafailedmessage');
    $scope.submittedlabel = $element.attr('data-submitted');

    $scope.init = function () {

    };

    $scope.submit = function () {
        var recaptchaResponse = grecaptcha.getResponse();
        if (!IsNullOrEmpty(recaptchaResponse)) {
            $('#submit-button').prop("disabled", true);
            var query = BuildQueryString();
            $http.post('/sitecore/api/ssc/webapi/forms/1/ContactUs' + $scope.queryString).success(function (response) {
                $scope.success = response.data.success;
                $scope.submitted = true;
                if ($scope.success) {
                    grecaptcha.reset();
                    $('[name="contactus"]').hide();
                    $('#introduction').hide();
                    $('#form-submit').html($scope.successMessage);
                    $('#submit-button').html($scope.submittedlabel);
                    $('html,body').animate({
                        scrollTop: 0
                    }, 500);
                }
                else {
                    $('#submit-button').prop("disabled", false);
                    $('#form-submit').html($scope.failureMessage);
                }
            }).error(function (data, status, headers, config) {
                $('#form-submit').html($scope.failureMessage);
            });
        } else {
            $('#form-submit').html($scope.recaptchaFailedMessage);
        }

        return false;
    };

    function BuildQueryString() {
        $scope.queryString = '';
        var fieldNames = ['name', 'email', 'inquirytype', 'message', 'disclaimer'];
        for (var index in fieldNames) {
            var fieldName = fieldNames[index];
            if (!IsNullOrEmpty($scope[fieldName]) && fieldName != 'inquirytype') {
                $scope.queryString = queryStringService.addQueryString(fieldName, encodeURIComponent($scope[fieldName]), $scope.queryString);
            }
            else if (!IsNullOrEmpty($scope[fieldName])) {
                var inquirytype = JSON.parse($scope[fieldName]);
                $scope.queryString = queryStringService.addQueryString('inquirytype', encodeURIComponent(inquirytype.name), $scope.queryString);
                $scope.queryString = queryStringService.addQueryString('inquiryemail', encodeURIComponent(inquirytype.email), $scope.queryString);
            }
        }
        var captchaResponse = grecaptcha.getResponse();
        $scope.queryString = queryStringService.addQueryString('captchaResponse', captchaResponse, $scope.queryString);
        return $scope.queryString;
    };

    function IsNullOrEmpty(value) {
        return (value == undefined || value == null || value == "");
    };

    $scope.init();
}]);