app.controller("KeyContactsController", ['$scope', function ($scope) {
    $scope.list = [];
    $scope.hasSearchRun = true;

    $scope.init = function () {
    };

    $scope.setValues = function (jsonModel) {
        if (jsonModel.List != null) {
            $scope.list = $scope.list.concat(jsonModel.List);
        }
    };

    $scope.HyperlinkClick = function (e, url) {
        window.location = url;
    };
}]);