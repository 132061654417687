app.factory("watchUrlService", ['$location', function ($location) {
    return {
        //Watch for url changes and execute XHR request
        watchStateChange: function (currentScope, doSearch) {

            var initializing = true;

            currentScope.$watch(function () { return $location.url(); }, function (url) {
                if (!initializing) {
                    doSearch();
                } else {
                    //Do Nothing
                    initializing = false;
                }
            });

        }
    };
}]);