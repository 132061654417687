app.factory('searchFilterService', ['$http', '$q', function ($http, $q) {
    return {
        get: function (type, lang) {
            return $http.get('/sitecore/api/ssc/webapi/search/1/GetFilters/?searchType=' + encodeURIComponent(type) + '&language=' + encodeURIComponent(lang))
                .then(function(response) {
                    if (typeof response.data === 'object') {
                        return response.data;
                    } else {
                        return $q.reject(response.data);
                    }

                }, function (response) {
                    return $q.reject(response.data);
                });
        }
    }
}]);